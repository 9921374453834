import React, { useState } from "react";
import { ReactComponent as Play } from './BaseAudio/assets/play.svg';
import { ReactComponent as Pause } from './BaseAudio/assets/pause.svg';

let audio_var = new Audio();

let kpl_audio = [
  "https://kipolmusic.s3.us-east-2.amazonaws.com/Kipol+-+first_state.mp3",
  "https://kipolmusic.s3.us-east-2.amazonaws.com/Nice_Mood_v6.mp3",
  "https://kipolmusic.s3.us-east-2.amazonaws.com/GENTWO_v3.mp3",
  "https://kipolmusic.s3.us-east-2.amazonaws.com/XlassicKPL_main_v10.mp3"
]

let random = Math.floor(Math.random() * kpl_audio.length);

let datasrc = kpl_audio[random];

audio_var.src = datasrc;

const BaseAudio  =  () => {
  const [isPlaying, setPlaying] = useState(true);

  const onPlayPauseClick = () => {
    
    isPlaying ? audio_var.play() : audio_var.pause();

    setPlaying(!isPlaying)
  }

  const togglePause = () => {
    if (audio_var.paused && audio_var.currentTime > 0 && !audio_var.ended) {
      audio_var.play();
    } else {
      audio_var.pause();
    }
    setPlaying(!isPlaying)
}

  return (
    <div className="audio-controls">
      {isPlaying ? (
        <button
          type="button"
          className="play"
          onClick={() => onPlayPauseClick()}
          aria-label="Play"
        >
          <Play />
        </button>
      ) : (
        <button
          type="button"
          className="pause"
          onClick={() => togglePause()}
          aria-label="Pause"
        >
          <Pause />
        </button>
      )}
    </div>
  );
}

export default BaseAudio;