import React from "react";
import { LinearCopy } from "gl-react";
import { Surface } from "gl-react-dom";
import GLTransition from "react-gl-transition";
import GLTransitions from "gl-transitions";
import timeLoop from "../../HOC/timeLoop";

import img1 from '../../artworks/42.jpg';
import img2 from '../../artworks/43.jpg';
import img3 from '../../artworks/48.jpg';
import img4 from '../../artworks/41.jpg';
import img5 from '../../artworks/38.jpg';
import img6 from '../../artworks/44.jpg';
import img7 from '../../artworks/45.jpg';
import img8 from '../../artworks/39.jpg';
import img9 from '../../artworks/46.jpg';
import img10 from '../../artworks/47.jpg';
import img11 from '../../artworks/40.jpg';

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
]

const Slideshow = timeLoop(({ slides, delay, duration, time }) => {
  const index = Math.floor(time / (delay + duration));
  const from = slides[index % slides.length];
  const to = slides[(index + 1) % slides.length];
  const transition = GLTransitions[36]; //5, 30, 36
  const total = delay + duration;
  const progress = (time - index * total - delay) / duration;
  return progress > 0
  ? <GLTransition
  from={from}
  to={to}
  progress={progress}
  transition={transition}
  />
  : <LinearCopy>{from}</LinearCopy>;
});

export default () => (
<Surface onClick={() => console.log("click on screen!")} width={window.innerWidth} height={window.innerHeight}>
  <Slideshow slides={images} delay={3000} duration={16000} /> 
  
</Surface>
);