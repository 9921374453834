import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import "./App.css";
// import Menu from "./Menu";
import Slider from "react-slick";
import './components/slider/slider.css';
import Basic from './components/shadertoy/Basic'
import Navbar from './components/Nav/Navbar'
import ReactPlayer from 'react-player'
import { Suspense } from "react"
import { Bolds } from "./Bolds"
import "./components/BaseAudio/base-audio.css";
import { Footer } from "./components/Footer";
import BaseAudio from "./components/BaseAudio";
import ArtObjects from "./components/ArtObjects";



// import deviceDetect from "device-detect";

// const { device } = deviceDetect();
// let linkType;

// if (["iPad", "iPod", "iPhone", "Blackberry"].indexOf(device) !== -1) {
//   linkType = "ios";
// } else if (["WindowsMobile", "Android"].indexOf(device) !== -1) {
//   linkType = "android";
// } else {
//   linkType = "desktop";
// }

const artworks_VS = {
  "zg1":{
    "id":1,
    "name": "zombie girl 1",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950176/kpl_feat_syrik/001-pgankpl-preset-v2-1gpu-fp16-network-snapshot-004631-000019_rlt_rlt_le37fp.png"
  },
  "zg2":{
    "id":2,
    "name": "zombie girl 2",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949913/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000137_rlt_rlt_yi5xgq.png"
  },
  "zg3":{
    "id":3,
    "name": "zombie girl 3",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950175/kpl_feat_syrik/001-pgankpl-preset-v2-1gpu-fp16-network-snapshot-004631-000023_rlt_rlt_azgyn2.png"
  },
  "zg4":{
    "id":4,
    "name": "zombie girl 4",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950174/kpl_feat_syrik/87_lwhuwa.png"
  },
  "zg5":{
    "id":5,
    "name": "zombie girl 5",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950174/kpl_feat_syrik/Woman_rlt_tzbsxj.png"
  },
  "zg6":{
    "id":6,
    "name": "zombie girl 6",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950171/kpl_feat_syrik/001-pgankpl-preset-v2-1gpu-fp16-network-snapshot-004631-000093_rlt_rlt_hsvact.png"
  },
  "zg7":{
    "id":7,
    "name": "zombie girl 7",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592950171/kpl_feat_syrik/45_blslhb.png"
  },
  "zg8":{
    "id":8,
    "name": "zombie girl 8",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949922/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000370_rlt_rlt_ppq6if.png"
  },
  "zg9":{
    "id":9,
    "name": "zombie girl 9",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949921/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000377_rlt_rlt_e9giaj.png"
  },
  "zg10":{
    "id":10,
    "name": "zombie girl 10",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949921/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000334_rlt_rlt_wskpeu.png"
  },
  "zg11":{
    "id":11,
    "name": "zombie girl 11",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949920/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000300_rlt_rlt_deieuu.png"
  },
  "zg12":{
    "id":12,
    "name": "zombie girl 12",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949911/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000016_rlt_rlt_cwfzqc.png"
  },
  "zg13":{
    "id":13,
    "name": "zombie girl 13",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949910/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000124_rlt_rlt_sksoa4.png"
  },
  "zg14":{
    "id":14,
    "name": "zombie girl 14",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1592949909/kpl_feat_syrik/043-pgansyrik_vs-preset-v2-1gpu-fp16-network-snapshot-005381-000097_rlt_rlt_kjqnav.png"
  },
  "dn1":{
    "id":1,
    "name": "dancers 1",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455702/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000012_rlt_rlt_akqu2q.png"
  },
  "dn2":{
    "id":2,
    "name": "dancers 2",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455696/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000005_rlt_rlt_e0mzut.png"
  },
  "dn3":{
    "id":3,
    "name": "dancers 3",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455684/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000093_rlt_rlt_m5h4xu.png"
  },
  "dn4":{
    "id":4,
    "name": "dancers 4",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455682/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000098_rlt_rlt_w4f8iu.png"
  },
  "dn5":{
    "id":5,
    "name": "dancers 5",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455677/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000092_rlt_rlt_qa49ab.png"
  },
  "dn6":{
    "id":6,
    "name": "dancers 6",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455671/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000076_rlt_rlt_qxs4uz.png"
  },
  "dn7":{
    "id":7,
    "name": "dancers 7",
    "materials": "acrylic on canvas",
    "size": "100x100 cm",
    "year": 2020,
    "url":"https://res.cloudinary.com/dahnjhzzu/image/upload/v1589455666/kpl_dancers/021-pgankpl-preset-v2-1gpu-fp16-network-snapshot-002254-000077_rlt_rlt_vjvppa.png"
  },
};

class Paintings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {artworksSubArray: artworks_VS};
  }
  
  render() {

  const Data = Object.values(this.state.artworksSubArray).map(p => {
    return (
      <div key={p.id}>
        <img src={p.url} alt='vs_gen' />
        <div className='artwork_title'>
          <h4>{p.name}</h4>
          <div>{p.materials}</div>
          <div>{p.size}</div>
          <div>{p.year}</div>
        </div>
      </div>
    );
  })

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  // var history = require('browser-history')

  return (
    <div>
      {/* <Menu /> */}
      <Navbar />
      <Basic />
      <BaseAudio />
      <Footer />

      <div className="container">
        <Slider {...settings}>
          {Data}
        </Slider>
      </div>
    </div>
    );
  }
}

class Music extends React.Component {
  
  render() {

    return (
      <div>
        <Navbar />
        <Basic />
        <Footer />
        <div className="soundcloud-container">
            <ReactPlayer 
              url='https://soundcloud.com/kipol_music'
              playing={true}
              controls= {true}
            />
        </div>
      </div>
      );
    }
  }

class Video extends React.Component {
  render() {

  return (
    <div className="main-container">
      <Navbar />
      <Basic />
      <Footer />
      <div className="soundcloud-container">
          <ReactPlayer 
            url='https://www.youtube.com/playlist?list=PLeUlLxUs7jCU0O-PP_M060ICeGUW-Wbr-'
          />
      </div>
    </div>
    );
  }
}

const Main = () => { 

  return (
    <div >
        <Navbar />
        <BaseAudio />
        <Footer />
        <div>
          {/* {linkType === "desktop" ? ( */}
            <Suspense fallback={null}>
            <Bolds />
          </Suspense>
          {/* ) : (
            <Basic /> 
          )} */}
        </div>
    </div>
  );
}


class App extends React.Component {
	render() {
	return (

<HashRouter basename="/">
  <div>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/artworks/">
          <ArtObjects />
        </Route>
        <Route path="/music/">
          <Music />
        </Route>
        <Route path="/video/">
          <Video />
        </Route> 
      </Switch>
  </div>
</HashRouter>

  )
  }
}

export default App;

