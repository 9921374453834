import React from "react";

export const Footer  =  () => {
  return (
    <div className="footer">
      <div>
        audio-visual artist
      </div>
      <div>
        web-developer
      </div>
    </div>
  );
}