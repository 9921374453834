// React
import React from 'react';
import ReactDOM from 'react-dom';

// CSS
import './css/main.css';
import './css/responsive.css';

// JS
import App from './App';

import * as serviceWorker from './js/serviceWorker';


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();